<template>
  <div id="expressYuanTong">
    <div class="printBoxYuanTong" v-for="item in printList" :key="item.pick_list_id" style="page-break-after:always;">
      <div class="printCompanyYuanTong" v-if="true">
        <div class="companyLogoYuanTong">
          <img src="~@/assets/yuantong.svg">
          <span>{{ item.waybill_no }}</span>
          <span>{{ currentTime }}</span>
        </div>
        <div class="companyLogoRight" v-if="false">
          <div style="display: flex;justify-content: space-between">
            <img src="~@/assets/huodaofukuan.svg" style="width: 9mm; height: 9mm; ">
            <img src="~@/assets/daofu.svg" style="width: 9mm; height: 9mm; margin-left: 1mm; margin-right: 1mm ">
            <img src="~@/assets/guojisanjian.svg" style="width: 9mm; height: 9mm; ">
          </div>
          <span>代收货款 ￥10000</span>
        </div>
      </div>
      <div class="waybillNumYuanTong">
        <jsbarcode-tem
          :code="item.waybill_no"
          :width="yuantong_barcode_width"
          :height="yuantong_barcode_height"
          :font-size="fontSize"
        ></jsbarcode-tem>
      </div>
      <div class="trackNumYuanTong">
        {{ item.short_address }}
      </div>
      <div class="sendReceiveAdBoxYuanTong">
        <div class="sendReceiveAdInfoYuanTong">
          <div class="receiveInfoBoxYuanTong">
            <span class="receiveYuanTong">收</span>
            <span class="receiveContentYuanTong">
              <span>{{ item.receiving_name }}  {{ item.receiving_phone }}</span>
              <br/>
              <span>{{ item.receiving_province + item.receiving_city + item.receiving_county + item.receiving_address_detail }}</span>
            </span>
          </div>
          <div class="sendInfoBoxYuanTong">
            <span class="sendYuanTong">寄</span>
            <span class="sendContentYuanTong">
              <span>{{ item.send_name }} {{ item.send_mobile }}</span>
              <br/>
              <span>{{ item.send_address }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="waybillNumParentYuanTong">
        <span class="waybillNumLeft">{{ item.package_place }}</span>
        <div class="waybillNumYuanTong2">
          <jsbarcode-tem
            :code="item.waybill_no"
            :width="yuantong_barcode_width2"
            :height="yuantong_barcode_height2"
            :fontSize="fontSize2" />
        </div>
      </div>
      <div class="jijianYuanTong">
        {{ item.product }}
        <!--          <div class="jijianItemYuanTong">-->
        <!--            <span>寄件码：12345</span>-->
        <!--            <span>产品名称：12345</span>-->
        <!--            <span>系统：xt</span>-->
        <!--          </div>-->
        <!--          <div class="jijianItemYuanTong">-->
        <!--            <span>运费：￥12</span>-->
        <!--            <span>数量：12</span>-->
        <!--            <span>品名：电子表</span>-->
        <!--          </div>-->
        <!--          <div class="jijianItemYuanTong">-->
        <!--            <span>取件员：张三</span>-->
        <!--            <span>重量：1kg</span>-->
        <!--          </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import jsbarcodeTem from '@/views/c-wms/pick/table/jsbarcodeTem'
import moment from 'moment/moment'
export default {
  name: 'YuanTongPrint',
  components: {
    jsbarcodeTem
  },
  data () {
    return {
      fontSize: '18px',
      fontSize2: '10px',
      yuantong_barcode_width: 1.8,
      yuantong_barcode_width2: 1.0,
      yuantong_barcode_height: 16,
      yuantong_barcode_height2: 10
    }
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    printList: {
      type: Array
    }
  },
  created () {
  },
  computed: {
    currentTime () {
      return moment().format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style scoped>
.printBoxYuanTong {
  position: relative;
  width: 76mm;
  /*margin-left: 15mm;*/
  margin: 0;
  padding: 0;
  height: 130mm;
  color: #000000;
  font-family: 'Microsoft YaHei',serif;
}

.printCompany {
  vertical-align: middle;
  border-bottom: 0.1px solid #000000;
  height: 14mm;
  line-height: 14mm;
  padding: 0 1.5mm;
}

.printCompanyYuanTong {
  border-bottom: 0.1px solid #000000;
  height: 20mm;
  display: flex;
  justify-content: space-between;
  padding: 0 0;
}

.companyLogo {
  width: 30mm;
  height: 14mm;
}

.companyLogoYuanTong {
  width: 42mm;
  height: 20mm;
  display: flex;
  justify-content: left;
  justify-items: center;
  flex-direction: column;
  padding: 1mm;
}

.companyLogoRight {
  width: 34mm;
  height: 20mm;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1mm;
}

.companyLogoRight > span {
  padding-top: 3mm;
  margin: 0;
  font-size: 8pt;
}

.companyLogoYuanTong > img {
  width: 26mm;
  height: 8mm;
}

.companyLogoYuanTong > span{
  padding-left: 2mm;
  margin: 0;
  font-size: 8pt;
}
/*.companyLogoYuanTong > span:last-child {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  font-size: 8pt;*/
/*}*/

.companyLogo > img {
  width: 26mm;
  height: 10mm;
  margin-top: 1.5mm;
}
.companyTip {
  float: right;
  font-size: 12pt;
  height: 8mm;
}

.trackNum {
  font-weight: bold;
  line-height: 18mm;
  height: 18mm;
  font-size: 60px;
  text-align: center;
  border-bottom: 1px solid #000000;
}

.trackNumYuanTong {
  font-weight: bold;
  line-height: 10mm;
  height: 10mm;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 22pt;
  text-align: center;
  border-bottom: 0.1mm solid #000000;
}

.packInfoBox {
  display: table;
}

.packGround {
  vertical-align: middle;
  border-bottom: 1px solid #000000;
  height: 10mm;
}
.ji {
  font-size: 16pt;
  font-weight: bold;
  width: 20mm;
  height: 20mm;
  border: 2px solid;
}
.packAd {
  font-size: 16pt;
  font-weight: bold;
  width: 74mm;
  height: 10mm;
  padding-left: 1.5mm;
  display: table-cell;
  vertical-align: middle;
}

.printTime {
  font-size: 5pt;
  display: table-cell;
  width: 24mm;
  border-left: 1px solid #000;
  vertical-align: middle;
}

.sendReceiveAdBox {
  border-bottom: 1px solid #000;
  height: 32mm;
}
.sendReceiveAdBoxYuanTong {
  border-bottom: 0.1mm solid #000;
  height: 38mm;
}

.waybillNumParentYuanTong {
  height: 12mm;
  width: 74mm;
  display: flex;
  text-align: center;
  justify-content: space-between;
  border-bottom: 0.1mm solid #000;
  box-sizing: border-box;
}

.jijianYuanTong {
  height: 18mm;
  width: 74mm;
  display: flex;
  padding: 2mm;
  flex-direction:row;
  justify-content: space-between;
  border-bottom: 0.1mm solid #000;
  box-sizing: border-box;
  font-size: 14pt;
}
.jijianItemYuanTong {
  display: flex;
  flex-direction:column;
  height: 6mm;
  text-align: left;
  font-size: 8pt;
}

.waybillNumLeft {
  font-size: 18pt;
  width: 26mm;
  height: 12mm;
  text-align: center;
  border-right: 1px solid #000;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}

.waybillNumYuanTong2 {
  width: 50mm;
  height: 12mm;
}

.sendReceiveAdInfo {
  display: table-cell;
  height: 30mm;
  width: 98mm;
}
.sendReceiveAdInfoYuanTong {
  display: table-cell;
  height: 38mm;
  width: 76mm;
}

.receiveInfoBox {
  border-bottom: 1px solid #000000;
  height: 16mm;
  width: 98mm;
}
.receiveInfoBoxYuanTong {
  border-bottom: 0.1mm solid #000000;
  height: 24mm;
  width: 72mm;
  padding: 1mm 1mm;

}

.receive {
  width: 5mm;
  height: 18mm;
  font-size: 8pt;
  border-right: 1px solid #000;
  display: table-cell;
  vertical-align: middle;
}
.receiveYuanTong {
  width: 8mm;
  height: 10mm;
  font-size: 16pt;
  display: table-cell;
  vertical-align: top;
}

.receiveContent {
  width: 98mm;
  height: 18mm;
  font-size: 8pt;
  padding-left: 1mm;
  display: table-cell;
}
.receiveContentYuanTong {
  width: 76mm;
  height: 24mm;
  font-size: 10pt;
  padding-left: 1mm;
  display: table-cell;
}
.receiveContentYuanTong >span:first-child {
  width: 76mm;
  height: 10mm;
  font-size: 10pt;
}
.receiveContentYuanTong >span:last-child {
  width: 76mm;
  height: 12mm;
  font-size: 10pt;
}

.sendInfoBox {
  height: 14mm;
  width: 98mm;
}
.sendInfoBoxYuanTong {
  height: 14mm;
  width: 76mm;
  padding: 1mm 1mm;
  box-sizing: border-box;
}

.send {
  height: 15mm;
  width: 5mm;
  border-right: 1px solid #000;
  font-size: 8pt;
  display: table-cell;
  vertical-align: middle;
}
.sendYuanTong {
  height: 10mm;
  width: 6mm;
  font-size: 12pt;
  display: table-cell;
  vertical-align: top;
}

.sendContent {
  padding-left: 1mm;
  width: 98mm;
  height: 15mm;
  font-size: 6pt;
  display: table-cell;
}
.sendContentYuanTong {
  padding-left: 1mm;
  width: 76mm;
  height: 8mm;
  display: table-cell;
}
.sendContentYuanTong >span:first-child {
  width: 76mm;
  height: 7mm;
  font-size: 8pt;
}
.sendContentYuanTong >span:last-child {
  width: 76mm;
  height: 7mm;
  font-size: 8pt;
}

.sendReceiveAdOpera {
  width: 24mm;
  border-left: 1px solid #000;
  display: table-cell;
  vertical-align: middle;
  height: 30mm;
}

.sendReceiveServer {
  width: 24mm;
  height: 5mm;
  border-bottom: 1px solid #000;
  text-align: center;
  font-size: 6px;
}

.serverInfo {
  width: 24mm;
  height: 25mm;
  font-size: 5pt;
}

.serverInfo > p {
  margin: 0;
  padding: 1px;
}

.waybillNum {
  margin-top: 5px;
  height: 26mm;
  font-size: 35px;
  border-bottom: 1px solid #000;
  text-align: center;
}
.waybillNumYuanTong {
  margin-top: 3px;
  height: 14mm;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 0.1mm solid #000;
  text-align: center;
}
.waybillNum2 {
  margin-top: 5px;
  height: 26mm;
  text-align: center;
  border-bottom: 1px solid #000;
}
#waybillNumCode {
  max-width: 70mm;
}

.singer {
  border-bottom: 2mm solid #000000;
  height: 19mm;
}

.singerTip {
  display: table-cell;
  width: 44mm;
  border-right: 1px solid #000;
  font-size: 6pt;
}

.singerName {
  font-size: 8pt;
  display: table-cell;
  width: 100mm;
}

.singerName > p {
  padding: 8px;
  margin: 0;
}
.singerName > p > span{
  width: 20mm;
}

.companyCode > div {
  display: inline-block;
  padding: 0 1mm;
}

.companyCode > div:nth-child(1) {
  width: 30mm;
  height: 14mm;
}

.companyCode > div:nth-child(2) {
  width: 68mm;
  height: 14mm;
  text-align: right;
  float: right;
}

.receiptBoxContent > p {
  margin: 0;
}
</style>
