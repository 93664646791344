<template>
  <a-modal
    title="操作"
    :width="680"
    :visible="visible"
    :closable="false"
  >
    <template slot="footer">
      <a-button type="primary" @click="$emit('cancel')">关闭</a-button>
    </template>
    <a-form :form="form" v-bind="formLayout">
      <a-row>
        <a-col :span="16">
          <a-form-item label="订单推送" >
            <a-switch disabled v-decorator="['is_push_order', { valuePropName: 'checked' }]" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-button v-if="!model.is_push_order" :shape="is_push_order_shape" :loading="is_push_order_loading" @click="push_order">{{ is_push_order_loading ? '推送中...' : '推送' }}</a-button>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="16">
          <a-form-item label="支付推送" >
            <a-switch disabled v-decorator="['is_push_pay', { valuePropName: 'checked' }]" />
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="false">
          <a-button v-if="!model.is_push_pay" :shape="is_push_pay_shape" :loading="is_push_pay_loading" @click="push_pay">{{ is_push_pay_loading ? '推送中...' : '推送' }}</a-button>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="16">
          <a-form-item label="物流推送" >
            <a-switch disabled v-decorator="['is_push_logistics', { valuePropName: 'checked' }]" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-button v-if="!model.is_push_logistics" :shape="is_push_logistics_shape" :loading="is_push_logistics_loading" @click="push_logistics">{{ is_push_logistics_loading ? '推送中...' : '推送' }}</a-button>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="16">
          <a-form-item label="清单推送" >
            <a-switch disabled v-decorator="['is_push_inventory', { valuePropName: 'checked' }]" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-button v-if="!model.is_push_inventory" :shape="is_push_inventory_shape" :loading="is_push_inventory_loading" @click="push_inventory">{{ is_push_inventory_loading ? '推送中...' : '推送' }}</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

import { pick_list_express_declaration, pick_list_push_inventory, pick_list_push_order } from '@/api/c_wms_picks'
const fields = ['is_push_order', 'is_push_pay', 'is_push_logistics', 'is_push_inventory']
export default {
  name: 'DeclarationStatusForm',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      order_no: '',
      is_push_order_loading: false,
      is_push_order_shape: undefined,
      is_push_pay_loading: false,
      is_push_pay_shape: undefined,
      is_push_logistics_loading: false,
      is_push_logistics_shape: undefined,
      is_push_inventory_loading: false,
      is_push_inventory_shape: undefined,
      form: this.$form.createForm(this),
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.loadEditInfo(this.model)
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      this.order_no = data.order_no
      const formData = pick(data, fields)
      form.setFieldsValue(formData)
    },
    push_order () {
      this.is_push_order_shape = 'round'
      this.is_push_order_loading = true
      const data = {
        order_no: this.order_no
      }
      pick_list_push_order(data).then(res => {
        if (res.code === 1000) {
          this.$message.success('操作成功')
        }
      }).finally(() => {
          this.is_push_order_shape = undefined
          this.is_push_order_loading = false
      })
    },
    push_pay () {
      let secondsToGo = 3
      this.is_push_pay_shape = 'round'
      this.is_push_pay_loading = true
      const interval = setInterval(() => {
        secondsToGo -= 1
      }, 1000)
      setTimeout(() => {
        clearInterval(interval)
        this.is_push_pay_shape = undefined
        this.is_push_pay_loading = false
      }, secondsToGo * 1000)
    },
    push_logistics () {
      if (this.model.waybill_no === '' || this.model.waybill_no === undefined) {
        this.$message.warning('物流单号为空')
        return
      }
      this.is_push_logistics_shape = 'round'
      this.is_push_logistics_loading = true
      const data = {
        waybill_no_list: [this.model.waybill_no]
      }
      pick_list_express_declaration(data).then(res => {
        if (res.code === 1000) {
          this.$message.success('操作成功')
        }
      }).finally(() => {
        this.is_push_logistics_shape = undefined
        this.is_push_logistics_loading = false
      })
    },
    push_inventory () {
      this.is_push_inventory_shape = 'round'
      this.is_push_inventory_loading = true
      const data = {
        order_no: this.order_no
      }
      pick_list_push_inventory(data).then(res => {
        if (res.code === 1000) {
          this.$message.success('操作成功')
        }
      }).finally(() => {
        this.is_push_inventory_shape = undefined
        this.is_push_inventory_loading = false
      })
    }
  }
}
</script>
