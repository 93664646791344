import request from '@/utils/request'
const requestApi = {
  wms_intercept_order_list: '/wms/common_warehouse/intercept_order/',
  wms_intercept_order_create: '/wms/common_warehouse/intercept_order/',
  wms_intercept_order_update: '/wms/common_warehouse/intercept_order/',
  wms_intercept_order_delete: '/wms/common_warehouse/intercept_order/',
  intercept_order_list: '/wms/common_warehouse/intercept_order_id/',
  inventory_cancel: '/wms/inventory_cancel/'
}

export function intercept_order_list (query) {
  return request({
    url: requestApi.wms_intercept_order_list,
    method: 'get',
    params: query
  })
}
export function intercept_order_list_id (query) {
  return request({
    url: requestApi.intercept_order_list,
    method: 'get',
    params: query
  })
}

export function intercept_order_create (data) {
  return request({
    url: requestApi.wms_intercept_order_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function intercept_order_update (data, intercept_order_id) {
  return request({
    url: requestApi.wms_intercept_order_update + intercept_order_id + '/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function intercept_order_delete (intercept_order_id) {
  return request({
    url: requestApi.wms_intercept_order_delete + intercept_order_id + '/',
    method: 'delete'
  })
}

export function inventory_cancel (data) {
  return request({
    url: requestApi.inventory_cancel,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
