var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"选择","width":380,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"物流"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'express',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]),expression:"[\n                'express',\n                {\n                  rules: [\n                    { required: true, message: '请选择' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"选择","allowClear":""}},_vm._l((this.$Dictionaries.logistics_company),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(val)+" ")])}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }