<template>
  <a-modal
    title="选择"
    :width="480"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="拣货人" >
              <a-select
                v-decorator="[
                  'pick_by_id',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
                @search="getUsers"
                option-filter-prop="children"
                :filter-option="filterOption"
                show-search
                allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in userList" :key="index">
                  {{ item.nickname }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="打包人" >
              <a-select
                v-decorator="[
                  'pack_by_id',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                @search="getUsers"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in userList" :key="index">
                  {{ item.nickname }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { user_list } from '@/api/user'

export default {
  name: 'SelectPickPackForm',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      userList: [],
      form: this.$form.createForm(this),
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      }
    }
  },
  methods: {
    getUsers (value) {
      user_list({ nickname: value }).then(({ data }) => {
        console.log(data, '我是用户列表')
        this.userList = data.entries || []
      })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
