<template>
  <div>
    <!-- 条形码容器，可选svg、canvas，或img标签 -->
    <svg :id="idName"></svg>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
export default {
  name: 'JsbarcodeTem',
  props: {
    tagCode: {
      type: String,
      default: 'svg'
    },
    code: {
      type: [String, Number],
      default: 'id'
    },
    width: {
      type: [Number],
      default: 2.5
    },
    height: {
      type: [Number],
      default: 50
    },
    fontSize: {
      type: [String],
      default: '26px'
    }
  },
  data () {
    return {
      idName: 'ok' + this.code + this.height
    }
  },
  mounted: function () {
// 生成条形码
    const that = this
      that.$nextTick(() => {
          const idName = that.idName
          JsBarcode(`#${idName}`, that.code, {
            height: that.height,
            width: that.width,
            text: that.code,
            textAlign: 'center',
            displayValue: true,
            fontSize: that.fontSize
          })
      })
  }
}
</script>
