import request from '@/utils/request'
const requestApi = {
  wms_goods_list: '/wms/warehouse/goods/',
  wms_goods_create: '/wms/warehouse/goods/',
  wms_goods_update: '/wms/warehouse/goods/',
  wms_goods_delete: '/wms/warehouse/goods/',
  wms_goods_category_list: '/wms/warehouse/goods_category/',
  wms_goods_category_create: '/wms/warehouse/goods_category/',
  wms_goods_category_update: '/wms/warehouse/goods_category/',
  wms_goods_category_delete: '/wms/warehouse/goods_category/',
  wms_goods_declaration_list: '/wms/warehouse/goods_declaration/',
  wms_goods_declaration_create: '/wms/warehouse/goods_declaration/',
  wms_goods_declaration_update: '/wms/warehouse/goods_declaration/',
  wms_goods_declaration_delete: '/wms/warehouse/goods_declaration/',
  wms_union_goods_list: '/wms/warehouse/union_goods/',
  wms_union_goods_create: '/wms/warehouse/union_goods/',
  wms_union_goods_update: '/wms/warehouse/union_goods/',
  wms_union_goods_delete: '/wms/warehouse/union_goods/',
  goods_list: '/wms/warehouse/goods_id/',
  wms_goods_inventory_record: 'wms/warehouse/goods_inventory_record/'
}

export function goods_list (query) {
  return request({
    url: requestApi.wms_goods_list,
    method: 'get',
    params: query
  })
}
export function goods_list_id (query) {
  return request({
    url: requestApi.goods_list,
    method: 'get',
    params: query
  })
}

export function goods_create (data) {
  return request({
    url: requestApi.wms_goods_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function goods_update (data, goods_id) {
  return request({
    url: requestApi.wms_goods_update + goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function goods_delete (goods_id) {
  return request({
    url: requestApi.wms_goods_delete + goods_id + '/',
    method: 'delete'
  })
}

export function goods_category_list (query) {
  return request({
    url: requestApi.wms_goods_category_list,
    method: 'get',
    params: query
  })
}

export function goods_category_create (data) {
  return request({
    url: requestApi.wms_goods_category_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function goods_category_update (data, goods_id) {
  return request({
    url: requestApi.wms_goods_category_update + goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function goods_category_delete (goods_id) {
  return request({
    url: requestApi.wms_goods_category_delete + goods_id + '/',
    method: 'delete'
  })
}

export function goods_declaration_list (query) {
  return request({
    url: requestApi.wms_goods_declaration_list,
    method: 'get',
    params: query
  })
}

export function goods_declaration_create (data) {
  return request({
    url: requestApi.wms_goods_declaration_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function goods_declaration_update (data, goods_id) {
  return request({
    url: requestApi.wms_goods_declaration_update + goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function goods_declaration_delete (goods_id) {
  return request({
    url: requestApi.wms_goods_declaration_delete + goods_id + '/',
    method: 'delete'
  })
}

export function union_goods_list (query) {
  return request({
    url: requestApi.wms_union_goods_list,
    method: 'get',
    params: query
  })
}

export function union_goods_create (data) {
  return request({
    url: requestApi.wms_union_goods_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function union_goods_update (data, goods_id) {
  return request({
    url: requestApi.wms_union_goods_update + goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function union_goods_delete (goods_id) {
  return request({
    url: requestApi.wms_union_goods_delete + goods_id + '/',
    method: 'delete'
  })
}
export function goods_inventory_record (query) {
  return request({
    url: requestApi.wms_goods_inventory_record,
    method: 'get',
    params: query
  })
}
