<template>
  <a-modal
    title="选择"
    :width="380"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="物流" >
              <a-select
                v-decorator="[
                  'express',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
                allowClear>
                <a-select-option
                  :key="key"
                  :value="key"
                  v-for="(val, key) in this.$Dictionaries.logistics_company">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { user_list } from '@/api/user'

export default {
  name: 'SelectExpressForm',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      userList: [],
      form: this.$form.createForm(this),
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 18 }
        }
      }
    }
  },
  methods: {
    getUsers (value) {
      user_list({ nickname: value }).then(({ data }) => {
        console.log(data, '我是用户列表')
        this.userList = data.entries || []
      })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
